<template>
  <div class="form-group">
    <span class="material-icons" aria-hidden="true">search</span>

    <input ref="inputElement" type="text" class="form-control icon-left" v-model="searchWord" aria-label="Suchbegriff eingeben" placeholder="Suchbegriff eingeben ..." v-on:keyup.enter="onEnter" @keypress.enter.prevent>
  </div>
</div>

</template>

<script>
  export default {
    name: 'KeywordSearch',
    props: {
      initialValue: {
        type: String,
        default: ""
      },
      focusMe: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        searchWord: '',
        awaitingSearch: false,
      }
    },
    watch: {
      initialValue(newWord){
        this.searchWord = newWord;
      },
      searchWord(newWord, oldWord){
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.$emit('searchWordChanged',this.searchWord);
            this.awaitingSearch = false;
          }, 1000); // 1 sec delay
        }
        this.awaitingSearch = true;
      },
    },
    mounted() {
      /*set the time to the initialValue*/
      this.searchWord = this.initialValue;
      if(this.focusMe){
        this.$refs.inputElement.focus();
      }
    },
    methods: {
      onEnter(){
        this.$refs.inputElement.blur();
        this.$emit('searchWordChanged',this.searchWord);
      },
      focus(){
        this.$refs.inputElement.focus();
      },
    }
  }
</script>